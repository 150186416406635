import React from 'react';
import { Text } from '@eo-locale/react';

import './queries-comp.scss';

const QueriesComp = () => {
    return (
        <div className="queries-block obv-width-100 obv-border-radius-10 obv-position-relative obv-z-index-1 obv-padding-top-10 obv-padding-bottom-10">
            <div className="col-12 obv-float-right text-center ">
                <div className="row obv-padding-left-30 obv-padding-right-30">
                    <div className="col-12 col-md-5">
                        <div className="row queries-list obv-margin-bottom-10">
                            <li className="col-4 text-center obv-font-size-12 obv-text-white obv-font-weight-400">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="queries"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/queries.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            527
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            <Text
                                                id="Mil_Queries"
                                                defaultMessage="Mil Queries"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-4 text-center obv-font-size-12 obv-text-white obv-font-weight-400">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="countries"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/countries.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            38
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            <Text
                                                id="Countries"
                                                defaultMessage="Countries"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-4 text-center obv-font-size-12 obv-text-white obv-font-weight-400">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="currency"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/currency.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            11
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            <Text
                                                id="Currencies"
                                                defaultMessage="Currencies"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="row queries-list">
                            <li className="col-4 col-sm-3 text-center obv-font-size-12 obv-text-white obv-font-weight-400">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="categories"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/categories.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            10
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            <Text
                                                id="Categories"
                                                defaultMessage="Categories"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-4 col-sm-3 text-center obv-font-size-12 obv-text-white obv-font-weight-400">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="unique-vehicle"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/unique-vehicle.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            05
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            <Text
                                                id="Mil_Unique_Vehicles"
                                                defaultMessage="Mil Unique Vehicles"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-4 col-sm-3 text-center obv-font-size-12 obv-text-white obv-font-weight-400 d-none d-sm-block">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="language"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/language.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            7
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            {
                                                <Text
                                                    id="Languages"
                                                    defaultMessage="Languages"
                                                />
                                            }{' '}
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li className="col-4 col-sm-3 text-center obv-font-size-12 obv-text-white obv-font-weight-400">
                                <div className="row">
                                    <figure className="col-3 col-sm-12 obv-margin-bottom-5 obv-margin-top-0">
                                        <img
                                            alt="patent"
                                            src="https://cdn.orangebookvalue.com/photos/images/web/obv-home-new/icons/patent.png"
                                            className="obv-width-auto"
                                        />
                                    </figure>
                                    <div className="col-9 col-sm-12">
                                        <span className="obv-font-size-18 obv-font-weight-500 obv-display-block obv-line-height-1_5">
                                            {
                                                <Text
                                                    id="USA"
                                                    defaultMessage="USA"
                                                />
                                            }{' '}
                                        </span>{' '}
                                        <span className="obv-font-size-10">
                                            <Text
                                                id="Patent"
                                                defaultMessage="Patent"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueriesComp;
